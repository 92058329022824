import * as sha256Browser from "@aws-crypto/sha256-browser";

function toHex(uint8Array) {
  // Array.from is not supported in IE
  return [].slice.call(uint8Array)
    .map((x) => {
      let xStr = x.toString(16);
      // padStart is not supported in IE, manually padding the string
      while (xStr.length < 2) {
        xStr = "0" + xStr;
      }
      return xStr;
    })
    .join('');
}

async function computeHash(text) {
  const hash = new sha256Browser.Sha256();
  hash.update(text);
  const result = await hash.digest();
  return toHex(result);
}

function subscribeToFayeChannel() {
  const fayeServerUrl = $("#faye-url").attr('data-fayeServerUrl');
  const fayeChannel = $("#channel").val(); // channel must be set in view
  const faye = new Faye.Client(fayeServerUrl);
  faye.subscribe(`/messages/${fayeChannel}`, () => $("#qr-form").submit());
}

function bindNavigationEvents() {
  const federationButton = $("#federation-button");
  const qrButton = $("#qrButton");
  const usernameButton = $("#usernameButton");

  if (federationButton) {
    federationButton.on("click", () => {
      ["qrField", "usernameField", "federation-button"].forEach(hideElement);
      ["qrButton", "usernameButton", "federation"].forEach(showElement);
    });
  }

  if (qrButton) {
    qrButton.on("click", () => {
      ["federation", "usernameField", "qrButton"].forEach(hideElement);
      ["federation-button", "usernameButton", "qrField"].forEach(showElement);
    });
  }

  if (usernameButton) {
    usernameButton.on("click", () => {
      ["federation", "qrField", "usernameButton"].forEach(hideElement);
      ["federation-button", "qrButton", "usernameField"].forEach(showElement);
    });
  }
}

// Waits for the onBfpDataLoad event
function waitForBfpDataLoad() {
  return new Promise((resolve) => {
    $(document).one("onBfpDataLoad", () => resolve());
  });
}

// Waits for a valid form submission
function waitForFormSubmit() {
  return new Promise((resolve) => {
    const signinForm = $("#form-signin");
    const qrForm = $("#qr-form");

    const onSubmitCallback = (event) => {
      event.preventDefault();
      resolve(event);
    }
    signinForm.on("submit", onSubmitCallback);
    qrForm.on("submit", onSubmitCallback);
  });
}

async function handleLoginFormSubmit() {
  // Submits the form once when the required conditions are met
  const [submitEvent] = await Promise.all([
    waitForFormSubmit(),
    waitForBfpDataLoad(),
  ]);
  submitEvent.target.submit();
}

async function generateQrCode() {
  const loginQrCode = $("#login-qr-code");
  const loginQrCodeFailureMsg = $("#qr-code-failure-msg");

  try {
    const qrcodeSvgUrl = new URL(loginQrCode.data("qrpath"), window.location.origin);

    const pathArray = qrcodeSvgUrl.pathname.split("/");
    pathArray[4] = await computeHash(window.location.hostname);
    qrcodeSvgUrl.pathname = pathArray.join("/");

    const qrCodeSvgData = await $.get(qrcodeSvgUrl.toString());
    if (qrCodeSvgData && qrCodeSvgData.documentElement) {
      loginQrCode.append(qrCodeSvgData.documentElement);
      animateDisplayTransition("qr-code-loader", "qr-code-container");
    } else {
      loginQrCodeFailureMsg.show();
    }
  } catch (error) {
    console.error(error);
    loginQrCodeFailureMsg.show();
  }
}

$(async () => {
  subscribeToFayeChannel();
  bindNavigationEvents();
  void generateQrCode();
  void handleLoginFormSubmit();
});
